<template>
    <div>
        <br>
        <h3 class="text-dark">Routes</h3>
        <br>
        <div v-if="response.routes.length > 0">
            <my-box>
                <my-table borderless striped :items="response.routes" :fields="table.fields" @search="loadRoutes" show-btn-ref></my-table>
            </my-box>
        </div>
        <div v-else>
            <my-loader title="Failed to load" :error-msg="errorMsg" :has-loaded="hasLoaded">
                <b-link variant="link" @click="() => {hasLoaded=false;loadRoles();}">Try Again</b-link>
            </my-loader>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errorMsg: null,
            hasLoaded: false,
            response: {
                routes: []
            },
            table: {
                fields: [
                    {
                        key: 'method',
                        sortable: true,
                        filter: 'equal'
                    },
                    {
                        key: 'name',
                        sortable: true,
                        filter: 'like'
                    },
                    {
                        key: 'available',
                        sortable: true,
                        filter: 'equal'
                    },
                    {
                        key: 'activities',
                        sortable: false,
                        label: '',
                    }
                ]
            },
        };
    },
    methods: {
        loadRoutes(filter) {
            this.$store.dispatch("credentials/route/get", { params: filter }).then(res => {
                this.response.routes = res.data.route 
            }).catch(err => {
                this.errorMsg = error.status + ' - ' + error.statusText
            })
        }
    },
    created() {
        this.loadRoutes()
    }
};
</script>